import React from 'react';
import './navpane.scss';
import { isBrowser } from "react-device-detect";
import { IoMdGlobe } from "react-icons/io";

function Navpane(props) {

    return (
        <div className='navpane'>

            <div className='navpane_left'>

                <div className='navpane_left_pagetitle'>

                    <div className='navpane_left_pagetitle_logo'>
                        <img src='./image/Embody_APP_28.png' alt=''></img>
                    </div>

                    <div className='navpane_left_pagetitle_name'>
                        <button>Embody Platform</button>
                    </div>

                </div>

                {isBrowser
                    ?
                    <div className='navpane_left_category'>
                        <a href='https://datgroup.com.vn/'>About us</a>
                        {/* <button>Product</button> */}
                        {/* <button>News</button> */}
                    </div>
                    : <></>
                }

            </div>

            {/* <div className='navpane_right'>
                <div className='navpane_right_languagebutton'>
                    <button><IoMdGlobe color='white' size={30} /></button>

                    <div className='navpane_right_languagebutton_language'>
                        <p>EN</p>
                    </div>

                </div>
            </div> */}

        </div>
    );
}

export default Navpane;