import React, { useState, useEffect } from 'react';
import './midpage.scss';
import { isBrowser } from "react-device-detect";

export default function Midpage(props) {
    const [popupState, SetPopupState] = useState("default");

    // Handle close when press ESC
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape") {
                SetPopupState("default");
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isBrowser
                ?
                <>
                    <div className='container'>
                        {popupState === "default"
                            ?
                            <>
                                <div className='container_head'>
                                    <p className='container_head_title'>Intelligent Monitoring System</p>
                                    <p className='container_head_subtitle'>Comprehensive technology designed to provide convenience in industrial monitoring, device control and data analysis.</p>
                                </div>

                                <div className='container_icon'>
                                    <button className='container_icon_item' onClick={(e) => SetPopupState("elev")}>
                                        <div className='container_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-26.png' alt=''></img>
                                        </div>

                                        <div className='container_icon_item_title'>
                                            <p>AIoT Elevator</p>
                                        </div>
                                    </button>

                                    <button className='container_icon_item' onClick={(e) => SetPopupState("auto")}>
                                        <div className='container_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-25.png' alt=''></img>
                                        </div>

                                        <div className='container_icon_item_title'>
                                            <p>AIoT Auto</p>
                                        </div>
                                    </button>

                                    <button className='container_icon_item' onClick={(e) => SetPopupState("energy")}>
                                        <div className='container_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-24.png' alt=''></img>
                                        </div>

                                        <div className='container_icon_item_title'>
                                            <p>AIoT Energy</p>
                                        </div>
                                    </button>
                                </div>
                            </>
                            : <></>
                        }
                    </div>

                    {(() => {
                        switch (popupState) {
                            case "auto":
                                return (
                                    <div className='automation' >
                                        <div className='automation_leftside'>
                                            <div className='automation_leftside_head'>
                                                <p className='automation_leftside_head_title'>Download AIoT Automation</p>
                                                <p className='automation_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automation_leftside_automation-popup'>
                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>

                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automation_rightside'>
                                            <button className='automation_rightside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automation_rightside_content'>
                                                <p>AIoT Automation Website</p>
                                                <img src='./image/autologo.png' alt=''></img>
                                                <a href='https://iot.embody.com.vn/'>Go to site</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "elev":
                                return (
                                    <div className='automation' >
                                        <div className='automation_leftside'>
                                            <div className='automation_leftside_head'>
                                                <p className='automation_leftside_head_title'>Download AIoT Elevator</p>
                                                <p className='automation_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automation_leftside_automation-popup'>
                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>

                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automation_rightside'>
                                            <button className='automation_rightside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automation_rightside_content'>
                                                <p>AIoT Elev Website</p>
                                                <img src='./image/elevatorlogo.png' alt=''></img>
                                                <a>Coming soon</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "energy":
                                return (
                                    <div className='automation' >
                                        <div className='automation_leftside'>
                                            <div className='automation_leftside_head'>
                                                <p className='automation_leftside_head_title'>Download AIoT Energy</p>
                                                <p className='automation_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automation_leftside_automation-popup'>
                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/Energy_IOS.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a href='https://apps.apple.com/vn/app/aiot-energy/id6484594898'>Get the app</a>
                                                </div>

                                                <div className='automation_leftside_automation-popup_scan'>
                                                    <img className='automation_leftside_automation-popup_scan_qrcode' src='./image/Energy_android.png' alt=''></img>
                                                    <img className='automation_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a href='https://play.google.com/store/apps/details?id=vn.com.embody.aiot_energy&hl=vi'>Get the app</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automation_rightside'>
                                            <button className='automation_rightside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automation_rightside_content'>
                                                <p>AIoT Energy Website</p>
                                                <img src='./image/energylogo.png' alt=''></img>
                                                <a href='https://energy.embody.com.vn/'>Go to site</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return <></>;
                        }
                    })()}
                </>
                :
                <>
                    <div className='containerMobile'>
                        {popupState === "default"
                            ?
                            <>
                                <div className='containerMobile_head'>
                                    <p className='containerMobile_head_title'>Intelligent Monitoring System</p>
                                    <p className='containerMobile_head_subtitle'>Comprehensive technology designed to provide convenience in industrial monitoring, device control and data analysis.</p>
                                </div>

                                <div className='containerMobile_icon'>
                                    <button className='containerMobile_icon_item' onClick={(e) => SetPopupState("elev")}>
                                        <div className='containerMobile_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-26.png' alt=''></img>
                                        </div>

                                        <div className='containerMobile_icon_item_title'>
                                            <p>AIoT Elevator</p>
                                        </div>
                                    </button>

                                    <button className='containerMobile_icon_item' onClick={(e) => SetPopupState("auto")}>
                                        <div className='containerMobile_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-25.png' alt=''></img>
                                        </div>

                                        <div className='containerMobile_icon_item_title'>
                                            <p>AIoT Auto</p>
                                        </div>
                                    </button>

                                    <button className='containerMobile_icon_item' onClick={(e) => SetPopupState("energy")}>
                                        <div className='containerMobile_icon_item_logo'>
                                            <img src='./image/1_Embody_APP-24.png' alt=''></img>
                                        </div>

                                        <div className='containerMobile_icon_item_title'>
                                            <p>AIoT Energy</p>
                                        </div>
                                    </button>
                                </div>
                            </>
                            : <></>
                        }
                    </div>

                    {(() => {
                        switch (popupState) {
                            case "auto":
                                return (
                                    <div className='automationMobile' >
                                        <div className='automationMobile_leftside'>
                                            <button className='automationMobile_leftside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automationMobile_leftside_head'>
                                                <p className='automationMobile_leftside_head_title'>Download AIoT Automation</p>
                                                <p className='automationMobile_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automationMobile_leftside_automation-popup'>
                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>

                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automationMobile_rightside'>
                                            <div className='automationMobile_rightside_content'>
                                                <p>AIoT Automation Website</p>
                                                <img src='./image/autologo.png' alt=''></img>
                                                <a href='https://iot.embody.com.vn/'>Go to site</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "elev":
                                return (
                                    <div className='automationMobile' >
                                        <div className='automationMobile_leftside'>
                                            <button className='automationMobile_leftside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automationMobile_leftside_head'>
                                                <p className='automationMobile_leftside_head_title'>Download AIoT Elevator</p>
                                                <p className='automationMobile_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automationMobile_leftside_automation-popup'>
                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>

                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/comingsoon.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a>Coming soon</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automationMobile_rightside'>
                                            <div className='automationMobile_rightside_content'>
                                                <p>AIoT Elev Website</p>
                                                <img src='./image/elevatorlogo.png' alt=''></img>
                                                <a>Coming soon</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "energy":
                                return (
                                    <div className='automationMobile' >
                                        <div className='automationMobile_leftside'>
                                            <button className='automationMobile_leftside_closebutton' onClick={(e) => SetPopupState("default")}>
                                                <ion-icon name="close-outline"></ion-icon>
                                            </button>
                                            <div className='automationMobile_leftside_head'>
                                                <p className='automationMobile_leftside_head_title'>Download AIoT Energy</p>
                                                <p className='automationMobile_leftside_head_subtitle'>Use your phone camera to scan the QR code below</p>
                                            </div>

                                            <div className='automationMobile_leftside_automation-popup'>
                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/Energy_IOS.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/app-store_5977575.png' alt=''></img>
                                                    <a href='https://apps.apple.com/vn/app/aiot-energy/id6484594898'>Get the app</a>
                                                </div>

                                                <div className='automationMobile_leftside_automation-popup_scan'>
                                                    <img className='automationMobile_leftside_automation-popup_scan_qrcode' src='./image/Energy_android.png' alt=''></img>
                                                    <img className='automationMobile_leftside_automation-popup_scan_icon' src='./image/playstore_300218.png' alt=''></img>
                                                    <a href='https://play.google.com/store/apps/details?id=vn.com.embody.aiot_energy&hl=vi'>Get the app</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='automationMobile_rightside'>
                                            <div className='automationMobile_rightside_content'>
                                                <p>AIoT Energy Website</p>
                                                <img src='./image/energylogo.png' alt=''></img>
                                                <a href='https://energy.embody.com.vn/'>Go to site</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return <></>;
                        }
                    })()}
                </>
            }
        </>
    );
}
