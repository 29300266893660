import { useRef, useState } from 'react';
import './Dropservice.scss';
import axios from 'axios';

export default function Dropservice() {
    const [warning, setWarning] = useState(false);
    const [confimDelete, setConfirmDelete] = useState(false);
    const [alert, setAlert] = useState(false);
    const [usr, setUsr] = useState('');
    const usernameRef = useRef("");
    const passwordRef = useRef("");

    const data = {
        username: 'admin',
        password: 'admin123'
    }

    const callApi = (method, url, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (method === 'get') {
                    await axios.get(url, {
                        headers: {
                            token: JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token'))
                        },
                        withCredentials: true
                    }).then(
                        function (res) {
                            resolve(res.data)
                        }
                    )
                } else {
                    return await axios.post(url, data, {
                        headers: {
                            token: JSON.parse(localStorage.getItem('token')) || JSON.parse(sessionStorage.getItem('token'))
                        },
                        withCredentials: true
                    }).then(
                        function (res) {
                            resolve(res.data)
                        }
                    )
                }
            } catch (e) {
                reject(e);
            }
        });
    }


    const handleDelete = async (e) => {
        if (usr !== 'solar_master') {
            e.preventDefault();
            const d = await callApi("post", process.env.REACT_APP_SOLAR + "/data" + "/removeUser", {
                usr: usr,
            });
            if (d.status) {
                console.log(d);
                setAlert(true);
            } else {
                console.log(d);
            }
        } else {
            console.log("solar_master can't be deleted");
        }
    }

    const handleLogin = (e) => {
        e.preventDefault();
        let auth = async () => {
            let res = await callApi("post", process.env.REACT_APP_SOLAR + "/auth" + "/Login", {
                username: usernameRef.current.value,
                password: passwordRef.current.value,
                lang: "en",
            });
            if (res.status) {
                console.log(res);
                sessionStorage.setItem("token", JSON.stringify(res.accessToken));
                setUsr(res.user);
                setWarning(false);
                setConfirmDelete(true);
            } else {
                setWarning(true);
            }
        };
        auth();
    }
    return (
        <div className="DAT_Main">
            <div className="DAT_Login_Head">
                <div className="DAT_Login_Head_Logo">
                    <img src={"/dat_icon/Embody_APP_24.png"} alt="" />
                </div>

                <div className="DAT_Login_Head_Title">
                    <div className="DAT_Login_Head_Title_Main">
                        {/* <img src={"/dat_icon/Embody_APP_27.png"} width={'150px'} height={'30px'} alt='' ></img> */}
                        AIOT Energy
                    </div>
                    <div className="DAT_Login_Head_Title_Sub">
                        Smart energy management system
                    </div>
                </div>
            </div>
            {confimDelete ?
                //MAN HINH XAC NHAN XOA TAI KHOAN
                <form className='DAT_Main_LoginBox'>
                    <div className='DAT_Main_LoginBox_Header'>
                        Comfirm Delete Account
                    </div>
                    <div className='DAT_Main_LoginBox_Body'>
                        {alert ? "Successfully deleted." :
                            "Are you sure to delete your account permanently? This action cannot be undone."
                        }
                    </div>
                    <div className='DAT_Main_LoginBox_Footer'>
                        {alert ?
                            <button onClick={(e) => { setConfirmDelete(false); setAlert(false); setUsr(''); setWarning(false) }}>Confirm</button>
                            : <>
                                <button onClick={(e) => { handleDelete(e) }}>Delete</button>
                                <button onClick={(e) => setConfirmDelete(false)}>Log out</button>
                            </>}


                    </div>
                </form> :
                //MAN HINH DANG NHAP
                <form onSubmit={(e) => handleLogin(e)} className='DAT_Main_LoginBox'>
                    <div className='DAT_Main_LoginBox_Header'>
                        Login
                    </div>
                    <div className='DAT_Main_LoginBox_Body'>
                        <div className='DAT_Main_LoginBox_Body_Input'>
                            <label>Username</label>
                            <input type="text" ref={usernameRef} />
                        </div>
                        <div className='DAT_Main_LoginBox_Body_Input'>
                            <label>Password</label>
                            <input type="password" ref={passwordRef} />
                        </div>
                        {warning ?
                            <div className='DAT_Main_LoginBox_Body_Noti'>
                                Your password or username is incorrect. Please try again.
                            </div> :
                            <></>}
                    </div>
                    <div className='DAT_Main_LoginBox_Footer'>
                        <button onClick={(e) => handleLogin(e)}>Login</button>
                    </div>
                </form>}


        </div>
    )
}
