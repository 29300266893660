import { useRef, useState } from 'react';
import './index.scss';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navpane from './components/navpane/navpane';
import Midpage from './components/midpage/midpage';
import Dropservice from './components/Dropservice/Dropservice';

function App() {


  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<div className="midpage">
            <Navpane />
            <Midpage />
          </div>} />
          <Route path="/dropservice" element={<Dropservice />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
